<template>
    <div>
        
        <div class="content content_top_margin" style="min-height: 175px;">
            <div class="content_inner  ">
                <div class="full_width ">
                    <div class="full_width_inner">

                        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
                            style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <h1>Working from the office<br>
                                                                                Desk</h1>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p>
                                                                                <router-link :to="{ name: 'keyboard-mouse-a45' }">
                                                                               <em>Up
                                                                                        Next: </em> Keyboard &amp; Mouse
                                                                                    <img class="vc_single_image-img attachment-full"
                                                                                        src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
                                                                                        alt="" width="64"
                                                                                        height="37">
                                                                                </router-link>
                                                                                        </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1637603238164"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-12">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>


                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <div class="heading_wrapper">
                                                            <h2>Finding the right height</h2>
                                                            <p><a class="headerVideoLink popup-modal"
                                                                   href="#" data-bs-toggle="modal"
																						data-bs-target="#exampleModal1"><img
                                                                        src="../../assets/images/airbnb/Airbnb-Video-Play.png">Watch
                                                                    video now</a></p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1636580782077"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-12">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_text_column wpb_content_element  no-margin">
                                                                        <div class="wpb_wrapper">
                                                                            <p>Whether you sit or stand when you work,
                                                                                set the height of your desk so that your
                                                                                keyboard and mouse are at the same
                                                                                height as your elbows. Your arms should
                                                                                hang comfortably at your sides, with
                                                                                your elbows bent at 90 degrees with your
                                                                                forearms parallel to the floor.</p>
                                                                            <p>&nbsp;</p>
                                                                            <p>If you stand while you work, remember to
                                                                                wear comfy shoes and, if available,
                                                                                stand on an anti-fatigue mat to help you
                                                                                stand longer and protect your feet and
                                                                                lower back.</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-12">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="vc_empty_space" style="height: 32px">
                                                                        <span class="vc_empty_space_inner">
                                                                            <span class="empty_space_image"></span>
                                                                        </span></div>


                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <h2>Switch it up</h2>
                                                                            <p>Your body will feel best if you work in a
                                                                                variety of positions. It is best to
                                                                                alternate between sitting and standing
                                                                                throughout the day. One of the most
                                                                                impactful strategies to adopt is to
                                                                                change position at least once an hour.
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h2>Continue your work from office journey</h2>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                        <router-link :to="{ name: 'posture-perfect-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Working-Office-Posture-Perfect.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Working-Office---Posture-Perfect"
                                                                                        >
                                                                                </div>
                                                                        </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'posture-perfect-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Posture perfect</h4>
                                                                                <p>It all starts with your posture no matter
                                                                                    where you work.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'wfo_seating-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="1120" height="621"
                                                                                        src="../../assets/images/airbnb/airbnb-WFO-seating-s.png"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="airbnb WFO seating (s)"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'wfo_seating-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Seating</h4>
                                                                            <p>It’s safe to say that you sit a lot
                                                                                during your workday.</p>

                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'your-desk-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Working-From-Office-Desk.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Working-From-Office---Desk"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'your-desk-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Your desk</h4>
                                                                            <p>Find your ideal desk height no matter
                                                                                what neighborhood you are in.</p>

                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'keyboard-mouse-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="501"
                                                                                        src="../../assets/images/airbnb/Airbnb-Working-From-Office-Keyboard-and-mouse.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb---Working-From-Office---Keyboard-and-mouse"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'keyboard-mouse-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Keyboard and mouse</h4>
                                                                                <p>Keep your keyboard and mouse as close to
                                                                                    you as possible.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'monitors-screens-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Working-From-Office-Monitor-and-Screens.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Working-From-Office---Monitor-and-Screens"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'monitors-screens-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Monitor and screens</h4>
                                                                                <p>Did you know your screen placement
                                                                                    determines your posture?</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'everyday-stretches-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="537"
                                                                                        src="../../assets/images/airbnb/Airbnb-Working-From-Office-Everyday-stretches.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Working-From-Office---Everyday-stretches"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'everyday-stretches-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Everyday stretches</h4>
                                                                            <p>Indulge in frequent stretch breaks to
                                                                                feel your best while working.</p>

                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">
                            <div class=" full_section_inner clearfix">
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_raw_code wpb_content_element wpb_raw_html">
                                                <div class="wpb_wrapper">
                                                    <div id="headerPopup" class="mfp-hide"
                                                        style="padding:56.25% 0 0 0;position:relative;"><iframe
                                                            src="https://player.vimeo.com/video/586047402?h=eed1da36da&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                                            frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen=""
                                                            style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                                            title="Posture Perfect (Standing)"></iframe></div>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed1"
												src="https://player.vimeo.com/video/586047402?h=eed1da36da&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>


                    </div>
                </div>
              

            </div>
        </div>

    </div>
</template>
<script>
    //import carousel from "vue-owl-carousel"
    export default {
        name: 'your-desk-a45',
         mounted(){
			document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
		},
         methods: {
			hideModal() {
			document.getElementById("closed1").src += "";
			},
		}
    }
</script>